class CookiesComponent extends HTMLElement {

  constructor() {
    super();

    this._template = document.createElement('template');
    this._cookiesSnippets = null;
    this._cookiesBox = null;
    this._cookiesModal = null;
    this._acceptAllBtns = null;
    this._declineAllBtns = null;
    this._customizeBtn = null;
    this._closeModalBtns = null;
    this._savePreferencesBtn = null;
    this._brand = null;
    this._cookiesWrapper = null;
    this._storageType = null;
    this._cookiesOptionsCheckboxes = null;
    this._openCookiesSettingsBtn = null;
    this._cookiesPreferences = {
      necessaryCookie: true,
      functionalCookie: this.cookiesPreferences['functionalCookie'] || false,
      analyticsCookie: this.cookiesPreferences['analyticsCookie'] || false,
      othersCookie: this.cookiesPreferences['othersCookie'] || false
    };
    this._cookiesBuiltEvent = new CustomEvent('cookiesBuilt', {
      bubbles: true,
      cancelable: false,
      detail: this._cookiesPreferences
    });

    setTimeout(() => this.setProps());
  }

  connectedCallback() {
    setTimeout(() => {
      // language=html
      this._template.innerHTML = `
      <style>
        .cookies-wrapper {
          --color-brand: ${ this.getAttribute('colorBrand') };
        }
        
        * {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }

        .d-flex {
          display: flex;
        }

        .d-none {
          display: none;
        }

        .d-block {
          display: block;
        }

        .align-items-center {
          align-items: center;
        }

        .justify-content-end {
          justify-content: flex-end;
        }

        .justify-content-space-between {
          justify-content: space-between;
        }

        .justify-content-center {
          justify-content: center;
        }

        button {
          border: 0;
          background-color: transparent;
          cursor: pointer;
          font-family: inherit;
        }

        ul {
          padding: 0;
          list-style-type: none;
        }

        .button {
          font-size: 15px;
          line-height: 20px;
          padding: 12px 23px;
          background-color: var(--color-brand);
          color: #fff;
          border: 1px solid transparent;
          cursor: pointer;
          min-width: 137px;
        }

        .button.button--text {
          padding: 0;
          color: var(--color-brand);
          background-color: transparent;
          border: 0;
          min-width: unset;
        }

        .button.button--ghost {
          color: var(--color-brand);
          background-color: transparent;
          border-color: var(--color-brand);
        }

        .cookies-box {
          position: fixed;
          left: 19px;
          bottom: 23px;
          max-width: 489px;
          right: 19px;
          background: #fff;
          z-index: 100000000000;
          box-shadow: 5px 0 10px #00000033;
          border-radius: 5px;
          padding: 20px 20px 13px 20px;
        }

        .cookies-box__title {
          font-size: 18px;
          line-height: 24px;
          color: var(--color-brand);
        }

        .cookies-box__text {
          color: #707070;
          font-size: 13px;
          line-height: 17px;
          margin-top: 15px;
          margin-bottom: 71px;
        }

        .cookies-box__buttons {
          margin-bottom: 31px;
          flex-direction: column-reverse;
        }

        @media screen and (min-width: 527px) {
          .cookies-box__buttons {
            flex-direction: row;
          }
        }

        .cookies-box__buttons__left {
          margin-top: 33px;
        }

        @media screen and (min-width: 527px) {
          .cookies-box__buttons__left {
            margin-right: 33px;
            margin-top: 0;
          }
        }

        .cookies-box__buttons__right {
          flex-direction: column-reverse;
          width: 100%;
        }

        @media screen and (min-width: 527px) {
          .cookies-box__buttons__right {
            flex-direction: row;
            width: unset;
          }
        }

        .cookies-box__buttons__right button {
          width: 100%;
        }

        @media screen and (min-width: 527px) {
          .cookies-box__buttons__right button {
            width: unset;
          }
        }

        .cookies-box__buttons__right button:first-of-type {
          margin-top: 15px;
        }

        @media screen and (min-width: 527px) {
          .cookies-box__buttons__right button:first-of-type {
            margin-right: 15px;
            margin-top: 0;
          }
        }

        .cookies-wrapper .links {
          justify-content: center;
        }

        @media screen and (min-width: 527px) {
          .cookies-wrapper .links {
            justify-content: flex-start;
          }
        }

        .cookies-wrapper .links .link {
          color: #707070;
          font-size: 12px;
          line-height: 16px;
          text-decoration: none;
          white-space: pre-wrap;
          cursor: pointer;
        }

        .cookies-wrapper .links .link.text-color-brand {
          color: var(--color-brand);
        }

        .cookies-wrapper .links .link:not(:last-child)::after {
          content: " | ";
          display: inline-block;
        }

        .cookies-modal {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, .5);
          z-index: 100000000000;
          padding: 23px 19px;
        }

        .cookies-modal__inner {
          width: 100%;
          max-width: 847px;
          max-height: 100%;
          background-color: #fff;
        }

        .cookies-modal__inner__top-bar {
          width: 100%;
          height: 30px;
          background-color: var(--color-brand);
          padding: 0 10px;
        }

        .cookies-modal__inner__top-bar img {
          width: 20px;
          cursor: pointer;
        }

        .cookies-modal__inner__content {
          overflow: auto;
          max-height: calc(100vh - 311px);
        }

        @media screen and (min-width: 600px) {
          .cookies-modal__inner__content {
            max-height: calc(100vh - 152px);
          }
        }

        .cookies-modal__inner__content__inner {
          padding: 30px 15px 0 15px;
        }

        .cookies-modal__inner__content__inner b {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 36px;
          color: var(--color-brand);
        }

        .cookies-options-list {
          margin-bottom: 55px;
        }

        .cookies-options-list .cookie-option {
          display: flex;
        }

        .cookies-options-list .cookie-option:not(:last-of-type) {
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 2px solid #F1F2F3;
        }

        .cookie-option .cookie-option__left {
          width: 100%;
          margin-right: 63px;
          padding-right: 15px;
        }

        .cookie-option__left .cookie-name {
          font-size: 15px;
          line-height: 20px;
          color: var(--color-brand);
          margin-bottom: 5px;
        }

        .cookie-option__left p {
          color: #707070;
          font-size: 12px;
          line-height: 16px;
        }

        .cookies-modal__inner__footer {
          padding: 15px;
          flex-direction: column-reverse;
        }

        @media screen and (min-width: 768px) {
          .cookies-modal__inner__footer {
            flex-direction: row;
          }
        }

        .cookies-modal__inner__footer__right {
          margin-bottom: 31px;
          flex-direction: column-reverse;
          width: 100%;
        }

        @media screen and (min-width: 600px) {
          .cookies-modal__inner__footer__right {
            flex-direction: row;
            width: unset;
          }
        }

        @media screen and (min-width: 768px) {
          .cookies-modal__inner__footer__right {
            margin-bottom: 0;
          }
        }

        .cookies-modal__inner__footer__right button {
          width: 100%;
        }

        @media screen and (min-width: 600px) {
          .cookies-modal__inner__footer__right button {
            width: unset;
          }
        }

        .cookies-modal__inner__footer__right button:not(:last-of-type) {
          margin-top: 10px;
        }

        @media screen and (min-width: 600px) {
          .cookies-modal__inner__footer__right button:not(:last-of-type) {
            margin-top: 0;
            margin-right: 10px;
          }
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
          border-radius: 20px;
        }

        .switch .slider {
          background-color: var(--color-brand);
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: all .2s;
          border-radius: 20px;
        }

        .switch .slider::before {
          background-color: #fff;
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          top: 1px;
          left: 1px;
          transition: all .2s;
          border-radius: 100%;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .switch input:checked + .slider::before {
          transform: translateX(20px);
        }

        .switch input:disabled + .slider {
          opacity: .5;
        }
      </style>
      
      <div class="cookies-wrapper ${ this.areCookiesPreferencesSet ? 'd-none' : '' }">
        <div class="cookies-box ${ this.areCookiesPreferencesSet ? 'd-none' : '' }">
          <b class="cookies-box__title">${ this._cookiesSnippets.cookiesBox.title }</b>
          <p class="cookies-box__text">
            ${ this._cookiesSnippets.cookiesBox.text }
          </p>
          <div class="cookies-box__buttons d-flex align-items-center justify-content-space-between">
            <div class="cookies-box__buttons__left">
              <button class="button button--text customize-btn">${ this._cookiesSnippets.cookiesBox.customizeButtonText }</button>
            </div>
            <div class="cookies-box__buttons__right d-flex align-items-center">
              <button class="button button--ghost decline-all-btn">${ this._cookiesSnippets.declineAllButtonText }</button>
              <button class="button accept-all-btn">${ this._cookiesSnippets.acceptAllButtonText }</button>
            </div>
          </div>
          <div class="links d-flex align-items-center">
            <a class="link" href="${ this._cookiesSnippets.cookiesBox.link1Href }">${ this._cookiesSnippets.cookiesBox.link1Text }</a>
            <a class="link" href="${ this._cookiesSnippets.cookiesBox.link2Href }">${ this._cookiesSnippets.cookiesBox.link2Text }</a>
          </div>
        </div>
        
        <div class="cookies-modal d-flex align-items-center justify-content-center d-none">
          <div class="cookies-modal__inner">
            <div class="cookies-modal__inner__top-bar d-flex align-items-center justify-content-end">
              <button class="d-flex align-items-center justify-content-center close-modal-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="50" stroke-width="2" d="M1.664 1.664l12.672 12.672"/></g><g><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="50" stroke-width="2" d="M14.336 1.664L1.664 14.336"/></g></g></svg>
              </button>
            </div>

            <div class="cookies-modal__inner__content">
              <div class="cookies-modal__inner__content__inner">
                <b class="d-block">${ this._cookiesSnippets.cookiesModal.title }</b>
                
                <ul class="cookies-options-list">
                  ${ this._cookiesSnippets.cookiesOptions.map(
        option => {
          return `
                        <li class="cookie-option">
                          <div class="cookie-option__left">
                            <div class="cookie-name">${ option.title }</div>
                            <p>${ option.text }</p>
                          </div>
                          <div class="cookie-option__right">
                            <label class="switch">
                              <input type="checkbox" ${ option.disabled ? 'disabled' : '' } ${ option.checked ? 'checked' : '' } name="${ option.name }">
                              <span class="slider"></span>
                            </label>
                          </div>
                        </li>
                      `;
        }
      ).join('') }
                </ul>
              </div>
            </div>
            
            <div class="cookies-modal__inner__footer d-flex align-items-center justify-content-space-between">
              <div class="cookies-modal__inner__footer__left">
                <div class="links d-flex align-items-center">
                  <button class="link close-modal-btn">${ this._cookiesSnippets.cookiesModal.backButtonText }</button>
                  <a class="link text-color-brand" href="${ this._cookiesSnippets.cookiesModal.moreInformationLinkHref }">${ this._cookiesSnippets.cookiesModal.moreInformationLinkText }</a>
                </div>
              </div>
              <div class="cookies-modal__inner__footer__right d-flex">
                <button class="button button--ghost button--ghost--${ this._brand } decline-all-btn">${ this._cookiesSnippets.declineAllButtonText }</button>
                <button class="button button--ghost button--ghost--${ this._brand } save-preferences-btn">${ this._cookiesSnippets.cookiesModal.savePreferencesButtonText }</button>
                <button class="button accept-all-btn">${ this._cookiesSnippets.acceptAllButtonText }</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

      this.attachShadow({ mode: 'open' }).appendChild(this._template.content.cloneNode(true));
      this.queryElements();
      this.initListeners();
    });
  }

  setProps() {
    this._brand = this.getAttribute('brand');
    this._storageType = this.getAttribute('storageType') || 'cookie';
    this._cookiesSnippets = {
      declineAllButtonText: this.getAttribute('declineAllButtonText') || 'Alle ablehnen',
      acceptAllButtonText: this.getAttribute('acceptAllButtonText') || 'Alle akzeptieren',
      cookiesBox: {
        title: this.getAttribute('title') || 'Cookies',
        text: this.getAttribute('text') || 'Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website zu verbessern, personalisierte Anzeigen oder Inhalte einzusetzen und unseren Datenverkehr zu analysieren. Wenn Sie auf „Alle akzeptieren" klicken, stimmen Sie der Anwendung von Cookies zu.',
        customizeButtonText: this.getAttribute('customizeButtonText') || 'Eigene Auswahl',
        link1Href: this.getAttribute('link1Href'),
        link2Href: this.getAttribute('link2Href'),
        link1Text: this.getAttribute('link1Text') || 'Impressum',
        link2Text: this.getAttribute('link2Text') || 'Datenschutzerklärung',
      },
      cookiesModal: {
        title: this.getAttribute('modalTitle') || 'Cookie Einstellungen',
        backButtonText: this.getAttribute('backButtonText') || 'Zurück',
        moreInformationLinkText: this.getAttribute('moreInformationLinkText') || 'Mehr erfahren',
        moreInformationLinkHref: this.getAttribute('moreInformationLinkHref'),
        savePreferencesButtonText: this.getAttribute('savePreferencesButtonText') || 'Auswahl speichern',
      },
      cookiesOptions: [
        {
          name: 'necessaryCookie',
          checked: this._cookiesPreferences.necessaryCookie,
          disabled: true,
          title: this.getAttribute('necessaryCookieTitle') || 'Technisch erforderlich',
          text: this.getAttribute('necessaryCookieText') || 'Wir nutzen Cookies, um die Bedienung der Website zu ermöglichen, und sicherzustellen, dass bestimmte Funktionen ordnungsgemäß funktionieren. Dieses Tracking ist immer aktiviert, da Sie sonst die Website nicht ordnungsgemäß bedienen können. Es werden keine personenbezogenen Daten gespeichert.',
        },
        {
          name: 'functionalCookie',
          checked: this._cookiesPreferences.functionalCookie,
          disabled: false,
          title: this.getAttribute('functionalCookieTitle') || 'Funktional',
          text: this.getAttribute('functionalCookieText') || 'Wir nutzen diese Cookies als Unterstützung bei der Ausführung bestimmter Funktionen, z. B. beim Teilen des Inhalts der Website auf Social Media-Plattformen, beim Sammeln von Feedbacks und anderen Funktionen von Drittanbietern.',
        },
        {
          name: 'analyticsCookie',
          checked: this._cookiesPreferences.analyticsCookie,
          disabled: false,
          title: this.getAttribute('analyticsCookieTitle') || 'Analytics',
          text: this.getAttribute('analyticsCookieText') || 'Wir nutzen diese Cookies um zu verstehen, wie Besucher mit der Website interagieren. Diese helfen uns für Aussagen über die Anzahl der Besucher, Absprungrate, Herkunft der Besucher usw.',
        },
        {
          name: 'othersCookie',
          checked: this._cookiesPreferences.othersCookie,
          disabled: false,
          title: this.getAttribute('othersCookieTitle') || 'Weitere',
          text: this.getAttribute('othersCookieText') || 'Cookies, die wir noch nicht klassifizieren konnten.',
        }
      ]
    };
  }

  queryElements() {
    this._acceptAllBtns = this.shadowRoot.querySelectorAll('.accept-all-btn');
    this._declineAllBtns = this.shadowRoot.querySelectorAll('.decline-all-btn');
    this._customizeBtn = this.shadowRoot.querySelector('.customize-btn');
    this._savePreferencesBtn = this.shadowRoot.querySelector('.save-preferences-btn');
    this._cookiesBox = this.shadowRoot.querySelector('.cookies-box');
    this._cookiesModal = this.shadowRoot.querySelector('.cookies-modal');
    this._closeModalBtns = this.shadowRoot.querySelectorAll('.close-modal-btn');
    this._cookiesWrapper = this.shadowRoot.querySelector('.cookies-wrapper');
    this._openCookiesSettingsBtn = document.querySelector('#open-cookies-settings');
    this._cookiesOptionsCheckboxes = this.shadowRoot.querySelectorAll(
      '.cookies-options-list input:not(:disabled)'
    );
  }

  initListeners() {
    this.acceptAllBtnsActions();
    this.declineAllBtnsActions();
    this.customizeBtnActions();
    this.savePreferencesBtnActions();
    this.closeModalBtnsActions();
    this.cookiesOptionsCheckboxesActions();
    this.openCookiesSettingsBtnActions();
  }

  showCookiesBox() {
    this.hideCookiesModal();
    this.showCookiesComponent();
    this._cookiesBox.classList.remove('d-none');
  }

  hideCookiesBox() {
    this._cookiesBox.classList.add('d-none');
  }

  showCookiesModal() {
    this.hideCookiesBox();
    this.showCookiesComponent();
    this._cookiesModal.classList.remove('d-none');
  }

  hideCookiesModal() {
    this._cookiesModal.classList.add('d-none');
  }

  showCookiesComponent() {
    this._cookiesWrapper.classList.remove('d-none');
  }

  hideCookiesComponent() {
    this.hideCookiesBox();
    this.hideCookiesModal();

    this._cookiesWrapper.classList.add('d-none');
  }

  acceptAllBtnsActions() {
    this._acceptAllBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        this.setCheckboxesState(true);
        this.saveCookiesPreferences();
      });
    });
  }

  declineAllBtnsActions() {
    this._declineAllBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        this.setCheckboxesState(false);
        this.saveCookiesPreferences();
      });
    });
  }

  customizeBtnActions() {
    this._customizeBtn.addEventListener('click', this.showCookiesModal.bind(this));
  }

  savePreferencesBtnActions() {
    this._savePreferencesBtn.addEventListener('click', this.saveCookiesPreferences.bind(this));
  }

  closeModalBtnsActions() {
    this._closeModalBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        this.areCookiesPreferencesSet ? this.hideCookiesComponent() : this.showCookiesBox();
      });
    });
  }

  openCookiesSettingsBtnActions() {
    this._openCookiesSettingsBtn?.addEventListener('click', this.showCookiesModal.bind(this));
  }

  cookiesOptionsCheckboxesActions() {
    this._cookiesOptionsCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', () => this._cookiesPreferences[checkbox.name] = checkbox.checked);
    });
  }

  getCookie(name) {
    if (this._storageType === 'localStorage') {
      return localStorage.getItem(name);
    } else {
      return (`; ${ document.cookie }`).split(`; ${ name }=`).pop().split(';')[0] || null;
    }
  }

  setCookie(name, value, days) {
    if (this._storageType === 'localStorage') {
      localStorage.setItem(name, value);
    } else {
      let expires = '';

      if (days) {
        const date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${ date.toUTCString() }`;
      }

      document.cookie = `${ name }=${ value }${ expires }; path=/`;
    }
  }

  setCheckboxesState(checked) {
    this._cookiesOptionsCheckboxes.forEach(checkbox => {
      checkbox.checked = checked;
      checkbox.dispatchEvent(new Event('change'));
    });
  }

  get areCookiesPreferencesSet() {
    return Object.keys(this.cookiesPreferences).length > 0;
  }

  get cookiesPreferences() {
    const cookie = this.getCookie('cookiesPreferences');

    return cookie ? JSON.parse(cookie) : {};
  }

  saveCookiesPreferences() {
    this.setCookie('cookiesPreferences', JSON.stringify(this._cookiesPreferences), '7');
    this.hideCookiesComponent();
    this.dispatchEvent(this._cookiesBuiltEvent);
  }
}

window.customElements.define('app-cookies', CookiesComponent);
